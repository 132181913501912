<template>
  <v-list-item :to="{ name: 'Chat', params: { chat_id: chat.id } }">
    <v-list-item-avatar>
      <v-img v-if="otherUser.avatar" aspect-ratio="1" :src="otherUser.avatar.link" />
      <v-img v-else aspect-ratio="1" src="@/assets/blank-avatar.png" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ otherUser.complete_name }} </v-list-item-title>
      <v-list-item-subtitle v-if="chat.latestMessage">
        {{ chat.latestMessage.user.isMe ? "You" : chat.latestMessage.user.name }}:
        <span class="text-ellips">{{ chat.latestMessage.text }}</span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon>
      <v-badge
        offset-x="10"
        offset-y="10"
        v-if="chat.unreadMessagesByMeCount > 0"
        color="danger"
        :content="chat.unreadMessagesByMeCount"
      >
        <v-icon color="primary"> mdi-message </v-icon>
      </v-badge>
      <v-icon v-else color="secondary"> mdi-message </v-icon>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
import gql from "graphql-tag";

export const CHAT_TILE_FRAGMENT = gql`
  fragment chatTile on Chat {
    id
    unreadMessagesByMeCount
    latestMessage {
      id
      text
      user {
        id
        name
        complete_name
      }
      created_at
    }
    users {
      id
      isMe
      complete_name
      avatar: file(name: "avatar") {
        id
        link
      }
    }
  }
`;

export default {
  name: "ChatTile",
  props: { chat: { type: Object, required: true } },
  computed: {
    otherUser() {
      return this.chat.users[0].isMe ? this.chat.users[1] : this.chat.users[0];
    },
  },
};
</script>
